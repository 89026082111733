import gsap from "gsap/gsap-core";
import { Timeline } from "gsap/gsap-core";

/* 
Hi! Since you're here and reading this – Why did I choose the songs on the website?
Strawberry Fields Forever – by the best band of all time, the music video inspired me to use songtitles as colornames
Purple Haze – I needed some violet in there, so why not use a great Hendrix' tune for this
Black Hole Sun – late Chris Cornell, one of the most exquisit voices in rock
Blue Monday – one of the great mishaps in pop music history
Fields of Gold – some Sting don't hurt no one
*/
let selectors = [
    { name: "#red", color: "#e45151" },
    { name: "#purple", color: "#ac8af0" },  // { name: "#purple", color: "#594461" },
    { name: "#black", color: "#ffffff" },
    { name: "#blue", color: "#66a9ef" },    // { name: "#blue", color: "#5353e4" or "4e5a6e" },
    { name: "#yellow", color: "#ffee93" }
];

const mediaQueryLarge = window.matchMedia('(max-width: 991.98px)');
let mobileLowerSectionReached = false; // if scroll position reaches a certain threshold, this will be set to true
let mobileShowHeaderImage = true;

let colorBar = document.querySelector("#color-bar");
let selectedColor = "#red";
positionColorBar(selectedColor); // choose initial position

// Add Event Listeners to the five color buttons
for (let i = 0; i < selectors.length; i++) {
    document.querySelector(selectors[i].name).addEventListener("click", e => {
        document.documentElement.style.setProperty("--bg-color", "#14100c");
        document.documentElement.style.setProperty("--alt-color", selectors[i].color);
        positionColorBar(selectors[i].name);
    });

    // Add Mobile Event listeners
    document.querySelector(selectors[i].name + "-mobile").addEventListener("click", e => {
        document.documentElement.style.setProperty("--bg-color", "#14100c");
        document.documentElement.style.setProperty("--alt-color", selectors[i].color);
        document.querySelector(".colored-songs-menu").classList.toggle("opened");
    });
}

function positionColorBar(colorName) {
    if (!selectedColor == colorName)
        selectedColor = colorName; // save this to global in case of a window resize event

    let bottomBarHeight = document.querySelector('.colored-songs-container').offsetHeight - 2; // 2 extra pixels needed here
    let rect = document.querySelector(colorName).getBoundingClientRect()

    currentPosition = (rect.right - rect.left) / 2 + rect.left;   // select the center of a color's bounding div
    currentPosition = currentPosition - colorBar.offsetWidth / 2; // substract half of bar's diameter

    colorBar.style.setProperty("left", currentPosition + "px");
    colorBar.style.setProperty("bottom", bottomBarHeight + "px");
}

// Add repositioning of colorBar on resize
window.addEventListener("resize", function () {
    positionColorBar(selectedColor);
}, true);

// Add some back to top behavior for logo button, but only on large screens
document.querySelector(".logo").addEventListener("click", function () {
    if (!mediaQueryLarge.matches) {
        scrollToTop();
    }
}, true);

// Add some back to top behavior for rotated textlink
document.querySelector("a.rotated").addEventListener("click", scrollToTop, true);

function scrollToTop() {
    document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

// Add mobile menu event listener
document.querySelector(".logo").addEventListener("click", e => {
    document.querySelector(".colored-songs-menu").classList.toggle("opened");
    for (let i = 0; i < selectors.length; i++) {
        document.querySelector(selectors[i].name + "-mobile").tabIndex = "0";
    }
});